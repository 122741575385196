import {useState} from 'react'
import {Button} from 'react-bootstrap'

import PipeLineStages from './components/PipeLineStages'
import UserPipelines from './components/UserPipelines'
import GenericOffcanvas from '../../modules/common/Offcanvas'
import {KTIcon} from '../../../_metronic/helpers'
import {useGetUserPipelineNotifications} from '../../queries/notifications'

import './PipeLines.scss'

function PipeLinesIndex() {
  const [showUserPipeLineModal, setShowUserPipeLineModal] = useState(false)
  const {data: pipelineNotifications} = useGetUserPipelineNotifications()
  const pipelineNotificationsResult = pipelineNotifications?.notifications

  return (
    <div className='d-flex' id='kt_pipeline_details_view'>
      <div className='d-block lg-sidebar'>
        <UserPipelines pipelineNotificationsResult={pipelineNotificationsResult} />
      </div>
      <div className='d-none sm-pipelines-sidebar'>
        <div className='d-flex justify-content-between'>
          <Button variant='transparent' size='sm' onClick={() => setShowUserPipeLineModal(true)}>
            <KTIcon iconName='entrance-left' className='fs-1' />
          </Button>
        </div>
        <GenericOffcanvas
          showOffcanvas={showUserPipeLineModal}
          onHideOffcanvas={() => setShowUserPipeLineModal(false)}
          children={<UserPipelines pipelineNotificationsResult={pipelineNotificationsResult} />}
        />
      </div>
      <div className='d-flex flex-column w-100 pipeline-stages-container p-5'>
        <PipeLineStages pipelineNotificationsResult={pipelineNotificationsResult} />
      </div>
    </div>
  )
}

export default PipeLinesIndex
