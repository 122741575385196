import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {WithChildren} from '../../../_metronic/helpers'

const Content: FC<WithChildren> = ({children}) => {
  const location = useLocation()

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div
      id='kt_content_container'
      className={`container-fluid ${location.pathname.includes('pipeline') ? 'max-w-full' : ''}`}
    >
      {children}
    </div>
  )
}

export {Content}
